<template>
  <div class="z-10 absolute top-5 right-5 flex items-center" :class="[{ 'short-description--isOpen': isShortDescription }]">
    <search-address class="mr-5" @center-map="$emit('center-map', $event)"></search-address>
    <monitoringTool class="mr-5" v-if="isShowenMonitoringButton" :isMonitoringButton="isMonitoringButton" @activateMonitoring="$emit('activateMonitoring')"></monitoringTool>
    <notificationsTool class="mr-5" :isNewAvailable="isNewAvailable" @toggleNotification="$emit('toggleNotification')"></notificationsTool>
    <button class="transition-colors duration-300 group text-darkblue bg-white h-9 w-40 rounded-full shadow flex flex-col focus_outline-none py-1 px-5 leading-none text-left hover_bg-lightblue relative" type="button" @click="isUserMenu = !isUserMenu">
      <span class="font-semibold group-hover_text-white font-SourceSansPro truncate w-32" style="font-size: 12px; line-height: 14px">{{ model.name }} </span>
      <span class="font-medium truncate text-lightblue w-24 truncate group-hover_text-white" style="font-size: 10px; line-height: 12px">
        {{ model.active_company.name }}
      </span>
    </button>
    <transition name="dialog-fade">
      <MainMenu v-if="isUserMenu" @changeCompany="changeCompany" @onClose="isUserMenu = false" @onShowHistory="$emit('show-history', $event)" />
      <!-- @onToggleDisplaySettings="isDisplaySettings = $event" -->
    </transition>
    <!-- new control company -->
    <!-- <el-popover
      v-model="isPopoverVisible"
      popper-class="menuUser-popover rounded-xl p-4 pb-5"
      placement="bottom"
      :open-delay="300"
      width="260"
      ref="popover"
      trigger="click"
      @hide="checkPopoverHiding"
    > -->

    <!-- <div class="menuPopup text-darkblue w-full"> -->
    <!-- Here we use v-if for making component lifecycle living for understanding if it stayed or not -->

    <!-- <div class="text-base font-bold font-SourceSansPro truncate">
          {{ model.name }}
        </div>
        <div class="text-xs text-lightblue font-medium font-SourceSansPro">
          {{ model.role.value }}
        </div>

        <el-select
          class="tools-select w-full"
          filterable
          :value="model.active_company.name"
          popper-class="selectCompany"
          :loading="loading"
          @change="changeCompany"
        >
          <el-option
            v-for="item in model.companies"
            :key="item.id"
            :value="item.id"
            :label="item.chcked ? item.text : item.name"
          ></el-option>
        </el-select>

        <p
          class="pt-3 cursor-pointer"
          v-if="isAdminPanel"
          @click="goAdminPanel()"
        >
          {{
            !model.active_company._admin_panel
              ? this.$t('admin-panel')
              : this.$t('come.back')
          }}
        </p>

        <p
          class="pt-3 cursor-pointer"
          v-if="isAccessAdminPanel"
          @click="handleAdminBeta"
        >
          {{ $t('tools.admin_panel_v2')
          }}<span class="betaTest">{{ $t('tools.admin_panel_beta') }}</span>
        </p>

        <p
          class="pt-3 cursor-pointer"
          v-if="isAllowedFinance"
          @click="handleFinance"
        >
          {{ $t('admin-panel.finance') }}
        </p>
        <p
          class="pt-3 cursor-pointer"
          v-if="notRole"
          @click="iscompanySettings"
        >
          {{ $t('setting-company') }}
        </p>

        <p class="pt-3 cursor-pointer" @click="openSettingUserModal">
          {{ $t('setting-user') }}
        </p>
        <p
          class="pt-3 cursor-pointer"
          @click="
            changePasswordModal = true
            isPopoverVisible = false
          "
        >
          {{ $t('users.change_password_title') }}
        </p>
        <div
          class="pt-3 cursor-pointer"
          v-if="notRole"
          @click="
            $refs['popover'].doClose()
            $emit('show-sessions')
          "
        >
          {{ $t('users.sessions') }}
        </div>
        <div class="pt-3 cursor-pointer" @click="logout">
          {{ $t('logout') }}
        </div> -->
    <!-- </div> -->

    <!-- <button
        class="transition-colors duration-300 group text-darkblue bg-white h-9 w-40 rounded-full shadow flex flex-col focus_outline-none py-1 px-5 leading-none text-left hover_bg-lightblue"
        slot="reference"
        type="button"
      >
        <span
          class="font-semibold group-hover_text-white font-SourceSansPro truncate w-32"
          style="font-size: 12px; line-height: 14px"
          >{{ model.name }}
        </span>
        <span
          class="font-medium truncate text-lightblue w-24 truncate group-hover_text-white"
          style="font-size: 10px; line-height: 12px"
        >
          {{ model.active_company.name }}
        </span>
      </button> -->
    <!-- </el-popover> -->

    <!-- <transition name="dialog-fade">
      <update-myself
        v-if="settingsUserModal"
        :title="2"
        :user="selectedUser"
        @close="close"
      ></update-myself>
    </transition>
    <transition name="dialog-fade">
      <change-password
        :isPasswordMine="true"
        v-if="changePasswordModal"
        @close="changePasswordModal = false"
      ></change-password>
    </transition>
    <transition name="dialog-fade">
      <company-settings
        v-if="settingsCompany"
        @close="settingsCompany = false"
        :companysettings="{ id: activeCompanyId }"
      ></company-settings>
    </transition>
    <transition name="dialog-fade">
      <div class="fixed bottom-5 top-5 left-5 z-30">
        <financeTable
          v-if="isFinance"
          @close="isFinance = false"
        ></financeTable>
      </div>
    </transition>
    <el-dialog
      class="myDialog"
      width="345px"
      :visible.sync="dialogVisible"
      :modalAppendToBody="false"
      @close="dialogVisible = false"
      top="30vh"
    >
      <template slot="title">
        <div class="betaDialog flex">
          <p class="title">Бета тест</p>
          <span class="betaTest mb-2">βeta</span>
        </div>
      </template>

      <p class="content">
        Хотим предупредить, что новая админ. панель находится на стадии
        разработки, поэтому будут доступны не все функции.
      </p>

      <span slot="footer" class="dialog-footer">
        <p class="cursor-pointer cancelBtn" @click="dialogVisible = false">
          Отменить
        </p>
        <p class="cursor-pointer saveBtn" @click="openBeta">Перейти</p>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import { companyApi } from '@/api'
import { closews } from '@/api/ws.js'
import { mapGetters, mapState } from 'vuex'
import { accessByRole } from '@/constants/roles.js'
import { toggleOpenedBoxTrack } from '@/services/reports/boxTrackReport'

// //- @click='$store.commit("map/EYE_TRIGGER")' used to clean the map
export default {
  name: 'tools',
  components: {
    changePassword: () => import('@/components/user/changePasswordModal'),
    companySettings: () => import('@/components/map/tools/companySettings'),
    selectLayers: () => import('@/components/map/tools/selectLayers'),
    notificationsTool: () => import('@/components/map/tools/notificationsTool.vue'),
    monitoringTool: () => import('@/components/map/tools/monitoringTool.vue'),
    searchAddress: () => import('@/components/map/tools/searchAddress'),
    cleanMapMenu: () => import('@/components/map/tools/cleanMapMenu'),
    helpTools: () => import('@/components/map/tools/HelpTools'),
    MainMenu: () => import('@/components/companyControlsCenter/components/MainMenu.vue'),
    financeTable: () => import('@/components/admin-panel/components/financeTable.vue')
  },
  props: ['isMonitoringButton', 'gottenNotifications'],
  data() {
    return {
      isPopoverVisible: false,
      isAdminPanel: false,
      changePasswordModal: false,
      loading: false,
      isLoading: false,
      settingsUserModal: false,
      settingsCompany: false,
      companyName: '',
      active_companyName: '',
      selectedUser: {},
      isCleaningMenu: false,
      isFinance: false,
      isFeedback: false,
      dialogVisible: false,
      // isDisplaySettings: false,
      isUserMenu: false
    }
  },
  computed: {
    ...mapState('login', {
      model: state => state.me
    }),
    ...mapState({
      roles: state => state.properties.roles
    }),
    ...mapGetters({
      activeCompanyId: 'login/activeCompanyId',
      role: 'login/role',
      notRole: 'login/notRole',
      isShortDescription: 'helpTools/isShortDescription'
    }),
    isAccessAdminPanel() {
      return !accessByRole.ADMIN.includes(this.role)
    },
    tzList() {
      return this.$store.getters['properties/getTZ']
    },
    isNewAvailable() {
      const newNotifLength = this.gottenNotifications.reduce((result, notify) => {
        return notify.isSeen ? result : [...result, notify]
      }, []).length
      if (newNotifLength) {
        document.title = `(${newNotifLength}) SKIF 2.0`
      } else {
        document.title = 'SKIF 2.0'
      }
      return newNotifLength
    },
    ...mapState('monitoring', {
      isShowenMonitoringButton: state => state.isShowenMonitoringButton
    }),
    isAllowedFinance() {
      const isBlockedCompany = this.model.active_company.billing && this.model.active_company.billing.company_status === 'BLOCKED'

      if (!this.notRole || isBlockedCompany) return false

      return true
    },
    integratorId() {
      return this.model.active_company.dealer.id
    }
  },
  watch: {
    model(value) {
      this.showAdminMenuItem(value)
    }
  },
  methods: {
    async openBeta() {
      localStorage.setItem('allowNewTab', 'true')
      await companyApi.getConfigs(response => {
        const adminAppUrl = response.data['admin-app']
        window.open(adminAppUrl, '_blank')
      })
      this.dialogVisible = false
    },
    handleAdminBeta() {
      const isNewTabAllowed = localStorage.getItem('allowNewTab') === 'true'

      if (isNewTabAllowed) {
        this.openBeta()
      } else {
        this.dialogVisible = true
        this.$refs.popover.doClose()
      }
    },
    handleFeedback() {
      this.isFeedback = true
    },
    handleFinance() {
      const url = `${window.location.origin}/api_v1/finance_excel?body=%7B%7D`
      console.log('handleFinance', url)
      window.open(url)
      this.isPopoverVisible = false
    },
    goAdminPanel() {
      let id
      if (this.model.active_company.is_admin_panel) {
        if (localStorage.getItem('active_company')) {
          id = localStorage.getItem('active_company')
        } else {
          id = this.model.companies.filter(val => !val.is_admin_panel)[0].id
        }
        this.changeCompany(id)
      } else {
        this.model.companies.forEach(val => {
          if (val.is_admin_panel) {
            id = val.id
          }
        })

        this.changeCompany(id)
      }
    },
    iscompanySettings() {
      this.settingsCompany = true
      this.isPopoverVisible = false
      this.$emit('issettings', 'issettings')
    },
    passLayer(layer) {
      this.$emit('selectLayer', layer)
    },
    addOverlay(overlay) {
      this.$emit('addOverlay', overlay)
    },
    removeOverlay(overlay) {
      this.$emit('removeOverlay', overlay)
    },
    openSettingUserModal() {
      this.isPopoverVisible = false
      this.$emit('update-myself', this.model, true, 'fromMenu')
    },
    close() {
      this.settingsUserModal = false
      this.settingsCompany = false
    },
    changeCompany(companyID) {
      console.log(companyID, 'companyID')
      this.$store.commit('map/EYE_TRIGGER', 'tracks')
      toggleOpenedBoxTrack(false)
      const component = this
      this.isLoading = true
      this.isPopoverVisible = false
      companyApi.changeCompany(
        companyID,
        () => {
          this.isUserMenu = false
          component.$store.commit('monitoringNew/setVuexSearchObject', '')
          component.$store.commit('login/COMPANY_CHANGED')
          this.$router.push({ path: '/' })
          component.$parent.getAllRequest().then(() => {
            component.isLoading = false
            // component.$store.dispatch('geozones/GET_GEOZONES')
          })
        },
        error => {
          console.log(error, 'error')
        }
      )
      this.$emit('change-company', 'changed')
      this.clearMonitoringLocalStorage()
    },
    clearMonitoringLocalStorage() {
      const monitoringLocalStorage = JSON.parse(localStorage.getItem('keysUnitsForMonitorings'))
      if (monitoringLocalStorage && monitoringLocalStorage.length > 0) {
        const emptyArr = []
        localStorage.setItem('keysUnitsForMonitorings', JSON.stringify(emptyArr))
      }
    },
    logout() {
      closews()
      this.$store
        .dispatch('login/logout')
        .then(() => {
          this.$emit('isLogout', 'logout')
          this.$router.push('login')
        })
        .catch(error => console.log(error))
      this.clearMonitoringLocalStorage()
    },
    showAdminMenuItem(model) {
      model.companies.forEach(val => {
        if (val.is_admin_panel) {
          this.isAdminPanel = true
        }
      })
    }
    // checkPopoverHiding() {
    //   const selectedText = window.getSelection().toString()
    //   const isTextSelected = selectedText.length > 0

    //   if (isTextSelected || this.isDisplaySettings) this.isPopoverVisible = true
    // }
  }
}
</script>

<style lang="stylus" scoped>
$blue = #5477A9;
.myDialog >>> .el-dialog__body{
  padding:10px 20px
}
.myDialog >>> .el-dialog__headerbtn{
  height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.myDialog >>> .el-icon-close{
  font-size: 26px;
  font-weight: bolder;
  color: $blue
}
.myDialog>>> .el-dialog__footer{
    border-top: 1px solid #CAD6DF;
    padding-left: 0;
    margin-top: 10px;
    padding-right: 20px;
  }
.myDialog {
  .content{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #253545;
    word-break: break-word;
  }
  .dialog-footer{
    display: flex;
    align-items: center;
    justify-content:space-around;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 120%;
    color: #FFFFFF;
    margin-left:10px;
    .cancelBtn{
      background: #C8D6DF;
      width: 140px;
      height: 32px;
      display:flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;

    }
    .saveBtn{
      display:flex;
      justify-content: center;
      align-items: center;
      border-radius: 6px;
      width: 140px;
      height: 32px;
      background: #446C9D;

    }
  }


}
.betaDialog {
  .title {
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 120%;
    color: #446C9D;

  .beta{
    color: red
  }
  }
}

.searchResult-enter-active, .searchResult-leave-active {
  transition: all 0.25s ease-in-out;
}

.searchResult-enter, .searchResult-leave-to {
  opacity: 0;
}

.search-enter-active, .search-leave-active {
  transition: all 0.25s ease-in-out;
}

.search-enter, .search-leave-to {
  opacity: 0;
}

.short-description {
  &--isOpen {
    right: 390px;
    transition: all 0.3s;
  }
}
.betaTest{
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  color: #FFFFFF;
  padding: 2px 4px
  background: linear-gradient(180deg, #2F83EB 0%, #2A78D7 100%);
  border-radius: 2px;
  margin-left: 6px
}
</style>
